import React from "react"
import { Breadcrumb } from "antd"

const BreadcrumbRevokeMember = ({ paths }) => {
  return (
    <Breadcrumb separator=">">
      {paths?.map((item) => (
        <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default BreadcrumbRevokeMember
